import { Grid } from '@achieve/ascend'
import { CardProduct } from 'components/Cards/CardProduct'
import { CardProductListItem } from 'components/Cards/CardProductListItem'

function ThreeProductCard({ content = {}, isMobile = true, productCardVariant }) {
  const ProductCardWrapper =
    productCardVariant === 'ProductCardsListItem' ? CardProductListItem : CardProduct
  return (
    <>
      {content && (
        <Grid
          container
          justifyContent={{ xs: 'center' }}
          alignItems={{ xs: 'stretch' }}
          spacing={{ xs: 2, md: 2 }}
          data-testid="home-product-card-section-card-grid"
          margin={0}
        >
          {(content.gridItems || []).map((gridContent, index) => {
            return (
              <Grid
                item
                md={8}
                lg={4}
                key={`${gridContent?.sys?.id}-${index}`}
                data-testid={`home-product-card-section-card-grid-item-${index}`}
              >
                <ProductCardWrapper
                  content={gridContent}
                  isMobile={isMobile}
                  index={index}
                  data-testid={`home-product-card-section-card-${index}`}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export { ThreeProductCard }
